body {
    background-color: #222;
    color: #fff;
  }
  
  .hero-section {
    /* background-color: #333; */
    /* position: relative; */
    /* position: absolute; */
    width: 100vw;
    height: 70vh;
    overflow: hidden;
    z-index: 0;
  }

  .hero-box {
    /* display: none; */
    height: 60vh;
  }
  
  .hero-section .logo {
    position: absolute;
    border-radius: 50%;
    bottom: 50%;
    max-height: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
  }

  .hero-video {
    width: 1920px;
    /* min-width: 1920px; */
    top: -200px;
    position: relative;
    /* position: absolute; */
    height: 1080px;
    /* min-height: 120vh; */
    /* height: -webkit-fill-available; */
    pointer-events: none;
  }

  .hero-video-container {
    left: 0;
    position: absolute;
    width: 100%;
    height: 70vh;
    overflow: hidden;
  }

  .vp-sidedock *{
    display: none;
  }

  .video-overlay {
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    /* background: rgba(0, 0, 0, 0.3); */
    /* border-radius: 16px; */
    /* box-shadow: 0 4px 30px rgba(255, 252, 252, 0.3); */
    backdrop-filter: saturate(300%);
    -webkit-backdrop-filter: saturate(300%);
  }
  
  .image-container {
    position: absolute;
    height: 700px;
    overflow: hidden;
    vertical-align: middle;
    /* top: 0; */
    bottom: 0;
    left: 0;
    width: 100vw;
    /* height: auto; */
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .image-container iframe{
    width: -webkit-fill-available;
    height: 100%;
  }
  
  .hero-image {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    filter: brightness(80%) saturate(150%) hue-rotate(340deg);
    transition: transform 10s linear;
  }
  
  .show {
    opacity: 100;
  }

  .show .hero-image {
    transform: scale(1.05) translate(1%, 1%);
  }
  
  .hide {
    opacity: 0;
  }

  .hide .hero-image {
    transform: scale(1);
    transition-delay: 5s;
  }
  
  .description {
    background-color: rgba(38, 50, 26, 1);
    position: absolute;
    bottom: 50%;
    min-width: 50vw;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .show .description {
    opacity: 100;
  }

  .hero-location {
    text-align: end;
    position: sticky;
    margin-left: auto;
    margin-right: 76px;
    top: 700px;
  }

  .hero-location a {
    /* padding: 1em; */
    color: white;
    /* color: #333; */
  /* background-color: white; */
  /* background-color: #C5F1B2;   */
  /* background-color: hsl(102, 69%, 95%);  */
  }
  
  /* Add more styles for other elements on the page */
  